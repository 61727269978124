import { computed, Ref } from '@vue/composition-api';
import * as N from '@ax/function-utils/nullable';
import * as R from '@ax/vue-utils/ref';
import { prop } from '@ax/object-utils';
import { constant, constZero, pipe } from '@ax/function-utils';
import {
  GetPolicyResultsList,
  runGetPolicyResultsListQuery,
  useGetPolicyResultsListQuery,
} from '@/services/policy-results.service';
import { PolicyResults } from '@/models/policy-results';

/**
 * Composition used to simplify getting the policy results list state.
 *
 * @param zoneId The zone UUId.
 * @param query The stringified query params
 * @returns The reactive state for policy results list.
 */
export function usePolicyResultsList(
  zoneId: Ref<string | undefined>,
  query: Ref<string | undefined>,
) {
  const getPolicyResultsListQuery = computed(() => {
    return N.isNonNullable(zoneId.value) && N.isNonNullable(query.value)
      ? new GetPolicyResultsList({
          zoneId: zoneId.value,
          query: query.value,
        })
      : undefined;
  });
  const { data: unfilteredData, ...remaining } = useGetPolicyResultsListQuery(
    getPolicyResultsListQuery,
  );

  const data = R.updateWhen_(unfilteredData, N.isNonNullable);

  const lastUpdated = R.map_(data, N.map(prop('timestamp')));

  const policyResultsList = computed(() =>
    pipe(
      data.value,
      N.map(prop('data')),
      N.getOrElse(constant<PolicyResults[]>([])),
    ),
  );

  const totalResults = computed(() =>
    pipe(
      data.value,
      N.map((result) => result.metadata.total_count),
      N.getOrElse(constZero),
    ),
  );

  function refresh() {
    if (getPolicyResultsListQuery.value) {
      return runGetPolicyResultsListQuery(getPolicyResultsListQuery.value);
    }
  }

  return {
    ...remaining,
    refresh,
    policyResultsList,
    totalResults,
    lastUpdated,
  };
}
