var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"policy-results"},[_c('div',{staticClass:"policy-results__overview"}),_c('ax-table',{attrs:{"headers":_vm.defaultPolicyResultsHeaders,"items":_vm.policyResultsList,"server-items-length":_vm.serverItemsLength,"item-key":"id","data-test-id":"policy-results-table","column-select":false,"options":_vm.tableState},on:{"update:options":function($event){return _vm.$emit('update:table-state', $event)}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"custom":"","to":{
          name: 'policyRunResultTab',
          query: { o: _vm.orgId },
          params: { policyUuid: item.uuid },
        }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var href = ref.href;
        var navigate = ref.navigate;
return [_c('a',{attrs:{"href":href},on:{"click":function (e) {
              _vm.trackPolicyDetailsRoute(_vm.user);
              navigate(e);
            }}},[_vm._v(" "+_vm._s(item.name)+" ")])]}}],null,true)})]}},{key:"item.execution_time",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.format(new Date(item.execution_time), _vm.DATE_TIME_ZONE_FORMAT))+" ")]}},{key:"item.type",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$t(("policy.type." + (item.type))))+" ")]}},{key:"item.total_results",fn:function(ref){
            var item = ref.item;
return [(item.total_results)?_c('router-link',{attrs:{"to":{
          path: '/devices',
          query: { o: _vm.orgId, policy: item.id },
        }}},[_vm._v(" "+_vm._s(item.total_results)+" ")]):[_vm._v("0")]]}},{key:"item.total_success",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'policyRunResultTab',
          query: { o: _vm.orgId.toString(), result: 'success' },
          params: { policyUuid: item.uuid },
        }}},[_vm._v(" "+_vm._s(item.total_success)+" ")])]}},{key:"item.statistics.pending",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'policyRunResultTab',
          query: { o: _vm.orgId.toString(), result: 'pending' },
          params: { policyUuid: item.uuid },
        }}},[_vm._v(" "+_vm._s(item.statistics.pending)+" ")])]}},{key:"item.statistics.failed",fn:function(ref){
        var item = ref.item;
return [_c('router-link',{attrs:{"to":{
          name: 'policyRunResultTab',
          query: { o: _vm.orgId.toString(), result: 'failed' },
          params: { policyUuid: item.uuid },
        }}},[_vm._v(" "+_vm._s(item.statistics.failed)+" ")])]}},{key:"item.statistics",fn:function(ref){
        var item = ref.item;
return [(!!item.total_results)?_c('v-lazy',[_c('ax-policy-results-table-doughnut',{attrs:{"item":item,"legend-config":_vm.legendConfig}})],1):_c('div',[_vm._v(" "+_vm._s(_vm.$t('general.notifications.unavailable'))+" "),_c('ax-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('span',_vm._g({staticClass:"ml-2 ax-text-teal",attrs:{"role":"button","tabindex":"0"}},on),[_c('v-icon',{attrs:{"role":"img","aria-hidden":"false","aria-label":_vm.$t('reports.policyResults.outsideWindowDisclaimer'),"dense":"","color":"teal"}},[_vm._v(" "+_vm._s(_vm.mdiInformationOutline)+" ")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$t('reports.policyResults.outsideWindowDisclaimer'))+" ")])],1)]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('ax-policy-results-actions',{attrs:{"segment-location":_vm.currentSegmentLocation,"policy-id":item.id,"policy-uuid":item.uuid}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }